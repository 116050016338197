import axios from "axios";
const baseURL =
    "https://cors-platform.herokuapp.com/https://leadgen.republish.nl/api/sponsors";

export default axios.create({
    baseURL,
    headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization:
            "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
    },
});
