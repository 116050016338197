import React from "react";
import { Container } from "reactstrap";
import logo from "../assets/img/logo.png";

const Header = () => {
    return (
        <Container className="py-2 header">
            <img src={logo} className="header__logo ml-3" />
        </Container>
    );
};

export default Header;
