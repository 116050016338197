const initialState = {
    loading: false,
    lead_response: "",
    error_response: null,
};

export default function leadReducer(state = initialState, { type, payload }) {
    switch (type) {
        case "SET_SECRET_ID":
            return {
                ...state,
                lead_response: payload,
            };
        case "ERROR_RESPONSE":
            return {
                ...state,
                error_response: payload,
            };
        case "SET_LOADING":
            return {
                ...state,
                loading: payload,
            };
        default:
            return { ...state };
    }
}
