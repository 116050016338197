import { RepositoryFactory } from "../../respository/RepositoryFactory";
let roof = RepositoryFactory.get("roof");

export const setLeadLoading = (val) => async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: val });
};

export const addLead = (payload, history) => async (dispatch) => {
    dispatch(setLeadLoading(true));
    if (payload.bootCheck) {
        dispatch(setLeadLoading(false));
        history.push("/dakisolatie/bedankt");
    } else {
        try {
            let { data } = await roof.addLead(payload);
            if (data) {
                dispatch({ type: "SET_SECRET_ID", payload: data });
                history.push("/dakisolatie/bedankt");
            }
            dispatch(setLeadLoading(false));
        } catch (error) {
            let { data } = error.response;
            console.log(data.error);
            if (data.error == "Conflict - duplicate") {
                dispatch(setLeadLoading(false));
                history.push("/dakisolatie/bedankt");
            } else {
                dispatch(setLeadLoading(false));
                let errorName = error.response.data.error
                    .match(/'([^']+)'/)[0]
                    .slice(1, -1);
                dispatch({
                    type: "ERROR_RESPONSE",
                    payload: { [errorName]: true },
                });
            }
        }
    }
};
export const setErrorResponse = () => async (dispatch) => {
    dispatch({ type: "ERROR_RESPONSE", payload: null });
};
