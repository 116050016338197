import Home from "../views/Home";
import Thankyou from "../views/ThankYou";

let routes = [
    // {
    //     path: "/dakisolatie",
    //     component: Home,
    //     layout: "main",
    // },
    // { path: "/dakisolatie/bedankt", component: Thankyou, layout: "main" },
];
export default routes;
