// Root Reducer

import { combineReducers } from "redux";
import leadReducer from "./leadReducer";

export let rootReducer = combineReducers({
    lead: leadReducer,
});

export default rootReducer;
